import { Card, Grid } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import OdysseyLogo from '../assets/odyssey-logo.svg'
import { media } from '../theme'
import { ApiRequest } from '../api/ApiTypes'
import server from '../api/server'
import { useLocation } from 'react-router'
import { parse } from 'qs'
import PoaEsignRequest from './PoaEsignRequest'

const InfoCard = styled(Card)`
  padding: 16px 24px;
  background-color: rgb(255, 255, 255, 0.95);
  ${media.mobile`
    background-color: rgb(255, 255, 255, 0.2);
  `}
`
const Odyssey = styled.img`
  width: 100%;
  margin-top: 16px;
`
const SuccessMessage = styled.p`
  color: green;
  font-weight: bold;
  margin-top: 16px;
`

const FailureMessage = styled.p`
  color: red;
  font-weight: bold;
  margin-top: 16px;
`

interface Payload {
  vin: string
  email: string
}

interface Response {
  data: {
    message: string
    email: string
    vin: string
    shipment_type: string
  }
}

const EmailVerify: React.FC = () => {
  const [verified, setVerified] = useState(false)
  const location = useLocation()
  const { token } = parse(location.search, { ignoreQueryPrefix: true }) as { token: string }
  const [email, setEmail] = useState<string>('')
  const [vin, setVin] = useState<string | null>(null)
  const [shipmentType, setShipmentType] = useState<string | null>(null)

  const apiCall: ApiRequest<Response, Payload> = {
    method: 'GET',
    path: `/users/verify_email?token=${token}`,
    onSuccess: ({ data }) => {
      setVerified(true)
      setEmail(data.email)
      setVin(vin)
      setShipmentType(data.shipment_type)
    },
    onFail: () => {
      setVerified(false)
    },
  }

  useEffect(() => {
    server(apiCall)
  }, [])

  return (
    <InfoCard>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={2} />
        <Grid item lg={6} xs={8}>
          <Odyssey src={OdysseyLogo} />
        </Grid>
        <Grid item xs={12}>
          {verified ? (
            <SuccessMessage>
              Your email has been successfully verified! You can now proceed.
              <PoaEsignRequest email={email} vin={vin} shipmentType={shipmentType} token={token} />
            </SuccessMessage>
          ) : (
            <FailureMessage>Email verification failed. Please check the verification link and try again.</FailureMessage>
          )}
        </Grid>
      </Grid>
    </InfoCard>
  )
}

export default EmailVerify

import { Card, Grid } from '@material-ui/core'
import React, { useState, FormEvent } from 'react'
import styled from 'styled-components'
import OdysseyLogo from '../assets/odyssey-logo.svg'
import StyledInput from './StyledInput'
import CTAButton from './CTAButton'
import LoadingDots from './LoadingDots'
import { media } from '../theme'
import { useSnackbar } from 'notistack'
import { ApiRequest } from '../api/ApiTypes'
import server from '../api/server'

const PoaCard = styled(Card)`
  padding: 16px 24px;
  background-color: rgb(255, 255, 255, 0.95);
  ${media.mobile`
    background-color: rgb(255, 255, 255, 0.2);
  `}
`
const Odyssey = styled.img`
  width: 100%;
  margin-top: 16px;
`
interface Payload {
  vin: string
  email: string
}
interface Response {
  message: string
}

const PoaForm: React.FC = () => {
  const [email, setEmail] = useState('')
  const [vin, setVin] = useState('')
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const handleSubmit = (e: FormEvent) => {
    setLoading(true)
    e.preventDefault()
    const apiCall: ApiRequest<Response, Payload> = {
      method: 'POST',
      path: '/users/send_verify_email',
      data: { email, vin },
      onSuccess: (data: Response) => {
        enqueueSnackbar(data.message, { variant: 'success' })
        setLoading(false)
      },
      onFail: () => {
        setLoading(false)
      },
    }
    server(apiCall)
  }

  return (
    <PoaCard>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={2} />
        <Grid item lg={6} xs={8}>
          <Odyssey src={OdysseyLogo} />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <StyledInput fullWidth placeholder='Email id' type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
            <StyledInput fullWidth placeholder='VIN' type='text' value={vin} onChange={(e) => setVin(e.target.value)} />
            <CTAButton type='submit'>{loading ? <LoadingDots /> : 'Submit'}</CTAButton>
          </form>
        </Grid>
      </Grid>
    </PoaCard>
  )
}

export default PoaForm

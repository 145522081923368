export const CUSTOMER_TYPE_OPTIONS = [
  { key: 'company', value: 'company', label: 'Company' },
  { key: 'person', value: 'person', label: 'Person' },
]

export const POA_LANGUAGE_OPTIONS = [
  { key: 'en', value: 'en', label: 'English' },
  { key: 'de', value: 'de', label: 'Deutsch' },
  { key: 'pl', value: 'pl', label: 'Polski' },
  { key: 'ua', value: 'ua', label: 'Ukrainian' },
]

export const CUSTOMER_COUNTRY = [
  { key: 'de', value: 'de', label: 'Germany' },
  { key: 'others', value: 'others', label: 'Others' },
]

export const SHIPMENT_TYPE_OPTIONS = [
  { key: 'boat', value: 'boat', label: 'Boat' },
  { key: 'car', value: 'car', label: 'Car' },
  { key: 'truck', value: 'truck', label: 'Truck' },
  { key: 'motor', value: 'motor', label: 'Motor' },
  { key: 'oldtimer', value: 'oldtimer', label: 'Oldtimer' },
  { key: 'quad', value: 'quad', label: 'Quad' },
  { key: 'package', value: 'package', label: 'Package' },
  { key: 'other', value: 'other', label: 'Other' },
]

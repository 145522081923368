import {
  Dialog,
  DialogActions,
  DialogContent,
  Step,
  StepButton,
  Stepper,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  Checkbox,
  makeStyles,
  DialogTitle,
} from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import React, { Suspense, useEffect, useState } from 'react'
import { fetchQuery, useFragment, useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks'
import { ShipmentInfoCard_shipment$key } from '../__generated__/ShipmentInfoCard_shipment.graphql'
import Button from './Button'
import FoldersCard from './File/FoldersCard'
import LinkedContactsCard from './LinkedContactsCard'
import useNiceMutation from '../mutations/useNiceMutation'
import { useSnackbar } from 'notistack'
import LoadingDots from './LoadingDots'
import { UploadDocumentsWizardGeneratablePoaDocsQuery } from '../__generated__/UploadDocumentsWizardGeneratablePoaDocsQuery.graphql'
import { Skeleton } from '@material-ui/lab'
import DialogFormActionBar from './Form/DialogFormActionBar'
import { ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation } from '../__generated__/ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation.graphql'

interface Props {
  slug: string
  open: boolean
  shipment: ShipmentInfoCard_shipment$key
  onClose: () => void
}

const Container = styled.div`
  margin: 40px 0;
`

const useStyles = makeStyles({
  customFormControl: {
    width: '200px', // Set your desired width
  },
})

const DOCUMENT_NAME_STEP_1 = 'Bill of Sale'

const UploadDocumentsWizard: React.FC<Props> = ({ slug, shipment: data, open, onClose }) => {
  const shipment = useFragment(fragment, data)
  const [steps, setSteps] = useState(['Upload Bill of Sale', 'Add Customer'])

  const completed = [!shipment.requiredDocuments?.includes(DOCUMENT_NAME_STEP_1), false]

  const [activeStep, setActiveStep] = React.useState(0)

  const handleDialogClose = () => {
    setActiveStep(0)
    onClose()
  }

  return (
    <>
      <Dialog open={open} maxWidth='md' onClose={handleDialogClose} scroll='paper' style={{ margin: 'auto' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton onClick={() => setActiveStep(index)} completed={completed[index]}>
                  {label}
                </StepButton>
              </Step>
            )
          })}
        </Stepper>
        {activeStep === 0 && (
          <UploadBillOfSaleStep shipment={data} setActiveStep={setActiveStep} slug={slug} activeStep={activeStep} onClose={onClose} />
        )}
        {activeStep === 1 && (
          <Suspense fallback={<FormSkeleton slug={slug} open={open} shipment={data} onClose={onClose} />}>
            <AddCustomerStep
              shipment={data}
              setSteps={setSteps}
              setActiveStep={setActiveStep}
              slug={slug}
              activeStep={activeStep}
              onClose={onClose}
            />
          </Suspense>
        )}
        {activeStep === 2 && (
          <Suspense fallback={<FormSkeleton slug={slug} open={open} shipment={data} onClose={onClose} />}>
            <AddPoaTemplate
              shipment={data}
              setSteps={setSteps}
              setActiveStep={setActiveStep}
              slug={slug}
              activeStep={activeStep}
              onClose={onClose}
            />
          </Suspense>
        )}
      </Dialog>
    </>
  )
}

const FormSkeleton: React.FC<Props> = ({ onClose }) => (
  <>
    <DialogTitle>Documents</DialogTitle>
    <DialogContent>
      <Skeleton variant='rect' height='50px' />
      <Skeleton variant='rect' height='50px' style={{ marginTop: '8px' }} />
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

interface StepContentProps {
  slug: string
  activeStep: number
  setActiveStep: (value: React.SetStateAction<number>) => void
  onClose: () => void
  shipment: ShipmentInfoCard_shipment$key
}

const UploadBillOfSaleStep: React.FC<StepContentProps> = ({ setActiveStep, shipment, slug }) => {
  return (
    <>
      <DialogContent>
        <FoldersCard
          key='documents'
          folderType='documents'
          // @ts-ignore
          folders={shipment.folders}
          title='Documents'
          entityType='shipments'
          entitySlug={slug}
          documentType={DOCUMENT_NAME_STEP_1}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => setActiveStep((prev) => prev + 1)}>
          Next: Add Customer
        </Button>
      </DialogActions>
    </>
  )
}

interface AddCustomerProps {
  slug: string
  activeStep: number
  setActiveStep: (value: React.SetStateAction<number>) => void
  onClose: () => void
  shipment: ShipmentInfoCard_shipment$key
  setSteps: (value: React.SetStateAction<string[]>) => void
}

const AddPoaTemplate: React.FC<AddCustomerProps> = ({ shipment, onClose, slug }) => {
  const [commitGenerateAndSendForSignature, generateAndSendForSignatureIsInFlight] =
    useNiceMutation<ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation>(generateAndSendForSignatureMutation)
  const { enqueueSnackbar } = useSnackbar()
  // @ts-ignore
  const foldersConnection = useFragment(fragment2, shipment.folders)

  const [email, setEmail] = useState<string>('')
  const [selectedLanguage, setSelectedLanguage] = useState<string>('')
  const [poaLanguage, setPoaLanguage] = useState<string>('de')
  const [selectCountry, setSelectCountry] = useState<string>('')
  const [countryError, setCountryError] = useState<boolean>(true)
  const [selectedCustomerType, setSelectedCustomerType] = useState<string>('')
  const [customerTypeError, setCustomerTypeError] = useState<boolean>(true)
  const [emailError, setEmailError] = useState<boolean>(true)
  const [esignLink, setEsignLink] = useState<string | null>(null)
  const data = useLazyLoadQuery<UploadDocumentsWizardGeneratablePoaDocsQuery>(generatablePoaDocsQuery, {
    entityType: 'shipments',
    entitySlug: slug,
    customerType: selectedCustomerType,
    language: selectedLanguage,
    customerCountry: selectCountry,
  })
  const generatableDocuments = data.generatablePoaDocumentsForExternal?.templateSlug || []
  const [templateList, setTemplateList] = useState<any>([])
  const customTemplateList = [
    {
      name: `vehicle-poa-${poaLanguage}`,
      selected: false,
    },
    {
      name: `general-poa-${poaLanguage}`,
      selected: false,
    },
    {
      name: `fiscal-poa-${poaLanguage}`,
      selected: false,
    },
    {
      name: `photo-id-${poaLanguage}`,
      selected: false,
    },
  ]
  const classes = useStyles()

  useEffect(() => {
    if (selectedLanguage && selectCountry && selectedCustomerType) {
      const apiLanguage = generatableDocuments.length > 0 ? generatableDocuments[0].slice(-2) : 'de'
      setPoaLanguage(apiLanguage)

      const generatableTemplateNames = new Set(generatableDocuments)
      const templates = [
        ...generatableDocuments.map((doc) => ({ name: doc, selected: true })),
        ...customTemplateList
          .filter((doc: { name: string }) => !generatableTemplateNames.has(doc.name.replace(/-\w{2}$/, `-${apiLanguage}`)))
          .map((doc: { name: string; selected: boolean }) => ({
            ...doc,
            name: `${doc.name.replace(/-\w{2}$/, '')}-${apiLanguage}`,
            selected: false,
          })),
      ]
      setTemplateList(templates)
    }
  }, [selectedLanguage, selectCountry, selectedCustomerType])

  const processESign = async () => {
    generateAndSendForSignature()
    setCustomerTypeError(false)
    setCountryError(false)
    setEmailError(false)
  }

  const generateAndSendForSignature = () => {
    const selectedTemplatesFromTemplateList = templateList
      .filter((template: { name: string; selected: boolean }) => template.selected)
      .map((template: { name: string; selected: boolean }) => template.name)

    commitGenerateAndSendForSignature({
      variables: {
        input: {
          entityType: 'shipments',
          // @ts-ignore
          entitySlug: shipment.slug,
          // @ts-ignore
          folderSlug: foldersConnection.nodes.filter((e) => e.parentType === 'documents')[0].slug,
          templateSlug: 'poa',
          templateList: selectedTemplatesFromTemplateList,
          customerType: selectedCustomerType,
          language: selectedLanguage,
          email: email,
          customerCountry: selectCountry,
        },
      },
      onCompleted: ({ insignGenerateAndRegisterDocumentForSigning }, err) => {
        setEsignLink(insignGenerateAndRegisterDocumentForSigning?.esignLink ?? '')
        if (!err) {
          enqueueSnackbar(`Documents successfully sent for e-Signature`, { variant: 'success' })
        }
      },
    })
  }

  const copyToClipboard = () => {
    if (esignLink) {
      navigator.clipboard.writeText(esignLink)
      enqueueSnackbar('e-Sign link copied to clipboard', { variant: 'success' })
    }
  }

  const handlePoaChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newLanguage = e.target.value as string
    const updatedTemplateList = templateList.map((template: { name: string; selected: boolean }) => ({
      ...template,
      name: `${template.name.slice(0, -2)}${newLanguage}`,
    }))

    setTemplateList(updatedTemplateList)

    setPoaLanguage(e.target.value as string)
  }

  const handleTemplateSelect = (name: string) => {
    setTemplateList((prevState: { name: string; selected: boolean }[]) =>
      prevState.map((template) => (template.name === name ? { ...template, selected: !template.selected } : template))
    )
  }

  return (
    <>
      <DialogContent>
        <FormControl fullWidth margin='normal'>
          <InputLabel>Customer type</InputLabel>
          <Select
            value={selectedCustomerType}
            onChange={(e) => {
              setSelectedCustomerType(e.target.value as string)
              setCustomerTypeError(false)
            }}
          >
            <MenuItem key={'company'} value={'company'}>
              Company
            </MenuItem>
            <MenuItem key={'person'} value={'person'}>
              Person
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin='normal'>
          <InputLabel>Customer Language</InputLabel>
          <Select value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value as string)}>
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'de'}>Deutsch</MenuItem>
            <MenuItem value={'pl'}>Polski</MenuItem>
            <MenuItem value={'ua'}>Ukrainian</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin='normal'>
          <InputLabel>Customer Country</InputLabel>
          <Select
            value={selectCountry}
            onChange={(e) => {
              setSelectCountry(e.target.value as string)
              setCountryError(false)
            }}
          >
            <MenuItem key={'de'} value={'DE'}>
              Germany
            </MenuItem>
            <MenuItem key={'others'} value={'others'}>
              Others
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin='normal'>
          <TextField
            size='small'
            variant='standard'
            value={email}
            label='Email'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value as string)
              if (e.target.value) {
                setEmailError(false)
              } else {
                setEmailError(true)
              }
            }}
          />
        </FormControl>
        {templateList.length > 0 && (
          <Container>
            <Typography variant={'subtitle1'}>The following documents will be generated</Typography>
            <FormControl fullWidth margin='normal' className={classes.customFormControl}>
              <InputLabel>POA Language</InputLabel>
              <Select
                value={poaLanguage}
                onChange={(e) => {
                  handlePoaChange(e)
                }}
              >
                <MenuItem value={'en'}>English</MenuItem>
                <MenuItem value={'de'}>Deutsch</MenuItem>
                <MenuItem value={'pl'}>Polski</MenuItem>
                <MenuItem value={'ua'}>Ukrainian</MenuItem>
              </Select>
            </FormControl>
            <List component='div' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%' }}>
              {templateList.map((template: { name: string; selected: boolean }, index: number) => (
                <ListItem key={index} alignItems='flex-start'>
                  <ListItemIcon>
                    <Checkbox
                      size='small'
                      checked={template.selected}
                      onChange={() => handleTemplateSelect(template.name)}
                      style={{ padding: '0 4px' }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={template.name} />
                </ListItem>
              ))}
            </List>
          </Container>
        )}

        {esignLink && (
          <FormControl fullWidth margin='normal'>
            <TextField
              label='e-Sign Link'
              value={esignLink}
              variant='outlined'
              InputProps={{
                endAdornment: (
                  <Button variant='contained' onClick={copyToClipboard}>
                    Copy
                  </Button>
                ),
              }}
              disabled
            />
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        {esignLink ? (
          <Button variant='contained' onClick={onClose}>
            {'Close'}
          </Button>
        ) : (
          <Button
            variant='contained'
            onClick={processESign}
            disabled={generateAndSendForSignatureIsInFlight || customerTypeError || emailError || countryError}
          >
            {generateAndSendForSignatureIsInFlight ? <LoadingDots variant='primary' /> : 'Send POA documents for e-Sign'}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

const AddCustomerStep: React.FC<AddCustomerProps> = ({ setActiveStep, shipment, onClose, setSteps, slug }) => {
  const [commitGenerateAndSendForSignature, generateAndSendForSignatureIsInFlight] =
    useNiceMutation<ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation>(generateAndSendForSignatureMutation)
  const { enqueueSnackbar } = useSnackbar()
  // @ts-ignore
  const foldersConnection = useFragment(fragment2, shipment.folders)
  const [selectedLanguage, setSelectedLanguage] = useState<string>('de')
  // const data = useLazyLoadQuery<UploadDocumentsWizardGeneratablePoaDocsQuery>(generatablePoaDocsQuery, {
  //   entityType: 'shipments',
  //   entitySlug: slug,
  // })
  // const generatableDocuments = data.generatablePoaDocumentsForExternal?.templateSlug || []
  const [templateList, setTemplateList] = useState<any>([])
  const customTemplateList = [
    {
      name: `vehicle-poa-${selectedLanguage}`,
      selected: false,
    },
    {
      name: `general-poa-${selectedLanguage}`,
      selected: false,
    },
    {
      name: `fiscal-poa-${selectedLanguage}`,
      selected: false,
    },
    {
      name: `photo-id-${selectedLanguage}`,
      selected: false,
    },
  ]

  const [hasCustomer, setHasCustomer] = useState<boolean>(false)
  const classes = useStyles()
  const environment = useRelayEnvironment()

  useEffect(() => {
    if (hasCustomer) {
      setSteps(['Upload Bill of Sale', 'Add Customer'])
      fetchQuery(environment, generatablePoaDocsQuery, {
        entityType: 'shipments',
        entitySlug: slug,
      })
        .toPromise()
        .then((data: any) => {
          const generatableDocuments = data.generatablePoaDocumentsForExternal?.templateSlug || []
          const apiLanguage = generatableDocuments.length > 0 ? generatableDocuments[0].slice(-2) : 'de'
          setSelectedLanguage(apiLanguage)

          const generatableTemplateNames = new Set(generatableDocuments)
          const templates = [
            ...generatableDocuments.map((doc: any) => ({ name: doc, selected: true })),
            ...customTemplateList
              .filter((doc) => !generatableTemplateNames.has(doc.name.replace(/-\w{2}$/, `-${apiLanguage}`)))
              .map((doc) => ({
                ...doc,
                name: `${doc.name.replace(/-\w{2}$/, '')}-${apiLanguage}`,
                selected: false,
              })),
          ]
          setTemplateList(templates)
        })
        .catch((error) => {
          console.error('Failed to fetch generatable documents:', error)
        })
    } else {
      setSteps((prev: string[]) => {
        if (!prev.includes('Choose POA template')) {
          return [...prev, 'Choose POA template']
        }
        return prev
      })
    }
  }, [hasCustomer, setSteps])

  const processESign = async () => {
    generateAndSendForSignature()
  }

  const generateAndSendForSignature = () => {
    const selectedTemplatesFromTemplateList = templateList
      .filter((template: { name: string; selected: boolean }) => template.selected)
      .map((template: { name: string; selected: boolean }) => template.name)

    commitGenerateAndSendForSignature({
      variables: {
        input: {
          entityType: 'shipments',
          // @ts-ignore
          entitySlug: shipment.slug,
          // @ts-ignore
          folderSlug: foldersConnection.nodes.filter((e) => e.parentType === 'documents')[0].slug,
          templateSlug: 'poa',
          templateList: selectedTemplatesFromTemplateList,
        },
      },
      onCompleted: (_, err) => {
        if (!err) {
          enqueueSnackbar(`Documents successfully sent for e-Signature`, { variant: 'success' })
        }
        onClose()
      },
    })
  }

  const handleHasCustomerChange = (value: boolean) => {
    setHasCustomer(value)
  }

  const handleLanguageChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newLanguage = e.target.value as string
    const updatedTemplateList = templateList.map((template: { name: string; selected: boolean }) => ({
      ...template,
      name: `${template.name.slice(0, -2)}${newLanguage}`,
    }))

    setTemplateList(updatedTemplateList)

    setSelectedLanguage(e.target.value as string)
  }

  const handleTemplateSelect = (name: string) => {
    setTemplateList((prevState: { name: string; selected: boolean }[]) =>
      prevState.map((template) => (template.name === name ? { ...template, selected: !template.selected } : template))
    )
  }

  return (
    <>
      <DialogContent>
        <LinkedContactsCard
          key='contacts'
          // @ts-ignore
          draftContacts={shipment.draftContacts}
          // @ts-ignore
          linkedContactRoles={shipment.linkedContactRoles}
          // @ts-ignore
          entitySlug={shipment.slug}
          entityType='shipment'
          // eslint-disable-next-line jsx-a11y/aria-role
          role='customer'
          onHasCustomerChange={handleHasCustomerChange}
        />
        {hasCustomer && (
          <Container>
            {templateList.length > 0 && (
              <>
                <Typography variant={'subtitle1'}>The following documents will be generated</Typography>
                <FormControl fullWidth margin='normal' className={classes.customFormControl}>
                  <InputLabel>POA Language</InputLabel>
                  <Select
                    value={selectedLanguage}
                    onChange={(e) => {
                      handleLanguageChange(e)
                    }}
                  >
                    <MenuItem value={'en'}>English</MenuItem>
                    <MenuItem value={'de'}>Deutsch</MenuItem>
                    <MenuItem value={'pl'}>Polski</MenuItem>
                    <MenuItem value={'ua'}>Ukrainian</MenuItem>
                  </Select>
                </FormControl>
                <List component='div' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%' }}>
                  {templateList.map((template: { name: string; selected: boolean }, index: number) => (
                    <ListItem key={index} alignItems='flex-start'>
                      <ListItemIcon>
                        <Checkbox
                          size='small'
                          checked={template.selected}
                          onChange={() => handleTemplateSelect(template.name)}
                          style={{ padding: '0 4px' }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={template.name} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Container>
        )}
      </DialogContent>
      <DialogActions>
        {hasCustomer ? (
          <Button variant='contained' onClick={processESign} disabled={generateAndSendForSignatureIsInFlight}>
            {generateAndSendForSignatureIsInFlight ? <LoadingDots variant='primary' /> : 'Send POA documents for e-Sign'}
          </Button>
        ) : (
          <Button variant='contained' onClick={() => setActiveStep((prev) => prev + 1)}>
            {hasCustomer ? 'Next' : 'Skip'}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

const fragment = graphql`
  fragment UploadDocumentsWizard_shipment on Shipment {
    slug
    requiredDocuments
  }
`

const generateAndSendForSignatureMutation = graphql`
  mutation UploadDocumentsWizardGenerateAndSendForSignatureMutation($input: insignGenerateAndRegisterDocumentForSigningInput!) {
    insignGenerateAndRegisterDocumentForSigning(input: $input) {
      clientMutationId
      esignLink
    }
  }
`

const fragment2 = graphql`
  fragment UploadDocumentsWizard_folders on FolderConnection {
    nodes {
      slug
      activeTicket
      parentType
      attachments {
        id
        documentType
      }
    }
  }
`

const generatablePoaDocsQuery = graphql`
  query UploadDocumentsWizardGeneratablePoaDocsQuery(
    $entityType: TemplateEntityTypeEnum!
    $entitySlug: String!
    $customerType: String
    $language: String
    $customerCountry: String
  ) {
    generatablePoaDocumentsForExternal(
      entityType: $entityType
      entitySlug: $entitySlug
      customerType: $customerType
      language: $language
      customerCountry: $customerCountry
    ) {
      templateSlug
    }
  }
`

export default UploadDocumentsWizard

import { Grid } from '@material-ui/core'
import React from 'react'
import { Route, Routes } from 'react-router'
import styled from 'styled-components'
import LandingBG from '../assets/public/landing.png'
import LogoCutSVG from '../assets/public/portagent-logo-cut.svg'
import PortagentLogo from '../assets/public/portagent-logo.png'
import Invitation from '../pages/Invitation'
import LandingPage from '../pages/LandingPage'
import LiveShareShow from '../pages/LiveShareShow'
import ResetPassword from '../pages/ResetPassword'
import { media } from '../theme'

const PublicContainer = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 0;
  padding-top: 16px;
  overflow: hidden;
  position: relative;
  background-image: url('${LandingBG}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;

  ${media.mobile`
    overflow-y: auto;
  `}

  ${media.tablet`
    overflow-y: auto;
  `}
`

const PublicContent = styled.div`
  width: 80%;
  margin: auto;
  z-index: 2;

  ${media.mobile`
    width:90%;
  `}

  ${media.desktop`
    width: 78%;
  `}
`

const LogoCut = styled.img`
  height: 180%;
  overflow: hidden;
  position: absolute;
  top: -40%;
  left: 10;
  z-index: -1;

  ${media.mobile`
    height: 140%;
    top: 0;
    left: 0;
  `}

  ${media.tablet`
    height: 130%;
    top: -5%;
    left: 0;
  `}
`

const Logo = styled.img`
  max-width: 20%;
  margin-left: -5%;

  ${media.mobile`
    max-width: 60%;
    margin-left: 0%;
    margin-bottom: 20px;
  `}

  ${media.tablet`
    max-width: 40%;
  `}

  ${media.giant`
    margin-left: -5%;
    max-width: 20%;
  `}
`

const Public: React.FC = () => {
  return (
    <PublicContainer>
      <PublicContent>
        <Grid container>
          <Grid item xs={12}>
            <Logo src={PortagentLogo} />
          </Grid>
        </Grid>
        <Routes>
          <Route path='/reset_password/:token' element={<ResetPassword />} />
          <Route path='/:workspace'>
            <Route path='/invitation/:token' element={<Invitation />} />
            <Route path='/live-share/:entityType/:entitySlug/:token' element={<LiveShareShow />} />
            <Route path='/' element={<LandingPage />} />
          </Route>
          <Route path='/*' element={<LandingPage />} />
          <Route path='/poa' element={<LandingPage form={'poa'} />} />
          <Route path='/verify' element={<LandingPage form={'verify'} />} />
        </Routes>
      </PublicContent>
      <LogoCut src={LogoCutSVG} />
    </PublicContainer>
  )
}

export default Public

import React, { useState } from 'react'
import NiceFormik from './Form/NiceFormik'
import { Form } from 'formik'
import PoaTemplateForm from './PoaTemplateForm'
import Button from './Button'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks'
import { PoaEsignRequestGenerateAndSendEsignQuery } from '../__generated__/PoaEsignRequestGenerateAndSendEsignQuery.graphql'
import LoadingDots from './LoadingDots'
import { useSnackbar } from 'notistack'
import { FormControl, TextField } from '@material-ui/core'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`

interface PoaEsignRequestProps {
  email: string
  vin: string | null
  shipmentType: string | null
  token: string
}

const PoaEsignRequest: React.FC<PoaEsignRequestProps> = ({ email, vin, shipmentType, token }) => {
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [esignLink, setEsignLink] = useState<string | null>(null)
  const environment = useRelayEnvironment()
  const { enqueueSnackbar } = useSnackbar()

  const initialValues = {
    customerType: '',
    language: '',
    customerCountry: '',
    email: email,
  }

  const handleGenerateAndSendEsign = (values: { customerType: any; language: any; customerCountry: any; email?: string }) => {
    setLoading(true)
    fetchQuery<PoaEsignRequestGenerateAndSendEsignQuery>(environment, query, {
      token: token,
      templates: selectedTemplates,
      customerLanguage: values.language,
      customerCountry: values.customerCountry,
      customerType: values.customerType,
    })
      .toPromise()
      .then((response) => {
        const poaEsign = response?.poaEsign
        setEsignLink(poaEsign?.esignUrl ?? null)
        if (poaEsign?.success) {
          enqueueSnackbar(poaEsign?.message, { variant: 'success' })
        } else {
          enqueueSnackbar(poaEsign?.message, { variant: 'error' })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const copyToClipboard = () => {
    if (esignLink) {
      navigator.clipboard.writeText(esignLink)
      enqueueSnackbar('e-Sign link copied to clipboard', { variant: 'success' })
    }
  }

  return (
    <>
      <NiceFormik
        initialValues={initialValues}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit={(values) => {
          handleGenerateAndSendEsign(values)
        }}
      >
        {({ submitForm }) => (
          <Form>
            <PoaTemplateForm vin={vin} vinShipmentType={shipmentType} setSelectedTemplates={setSelectedTemplates} />
            <ButtonContainer>
              <Button variant='contained' onClick={submitForm} disabled={loading}>
                {loading ? <LoadingDots variant='primary' /> : 'Send POA documents for e-Sign'}
              </Button>
            </ButtonContainer>

            {esignLink && (
              <FormControl fullWidth margin='normal'>
                <TextField
                  label='e-Sign Link'
                  value={esignLink}
                  variant='outlined'
                  InputProps={{
                    endAdornment: (
                      <Button variant='contained' onClick={copyToClipboard}>
                        Copy
                      </Button>
                    ),
                  }}
                  disabled
                />
              </FormControl>
            )}
          </Form>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query PoaEsignRequestGenerateAndSendEsignQuery(
    $token: String!
    $templates: [String!]!
    $customerLanguage: String!
    $customerCountry: String!
    $customerType: String!
  ) {
    poaEsign(
      token: $token
      templates: $templates
      customerLanguage: $customerLanguage
      customerCountry: $customerCountry
      customerType: $customerType
    ) {
      esignUrl
      success
      message
    }
  }
`

export default PoaEsignRequest

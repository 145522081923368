/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TemplateEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type ExternalUploadDocumentsWizardGeneratablePoaDocsQueryVariables = {
    entityType: TemplateEntityTypeEnum;
    entitySlug: string;
    customerType?: string | null;
    language?: string | null;
    customerCountry?: string | null;
};
export type ExternalUploadDocumentsWizardGeneratablePoaDocsQueryResponse = {
    readonly generatablePoaDocumentsForExternal: {
        readonly templateSlug: ReadonlyArray<string>;
    };
};
export type ExternalUploadDocumentsWizardGeneratablePoaDocsQuery = {
    readonly response: ExternalUploadDocumentsWizardGeneratablePoaDocsQueryResponse;
    readonly variables: ExternalUploadDocumentsWizardGeneratablePoaDocsQueryVariables;
};



/*
query ExternalUploadDocumentsWizardGeneratablePoaDocsQuery(
  $entityType: TemplateEntityTypeEnum!
  $entitySlug: String!
  $customerType: String
  $language: String
  $customerCountry: String
) {
  generatablePoaDocumentsForExternal(entityType: $entityType, entitySlug: $entitySlug, customerType: $customerType, language: $language, customerCountry: $customerCountry) {
    templateSlug
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerCountry"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entitySlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "language"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "customerCountry",
        "variableName": "customerCountry"
      },
      {
        "kind": "Variable",
        "name": "customerType",
        "variableName": "customerType"
      },
      {
        "kind": "Variable",
        "name": "entitySlug",
        "variableName": "entitySlug"
      },
      {
        "kind": "Variable",
        "name": "entityType",
        "variableName": "entityType"
      },
      {
        "kind": "Variable",
        "name": "language",
        "variableName": "language"
      }
    ],
    "concreteType": "GeneratablePoaDocumentsForExternal",
    "kind": "LinkedField",
    "name": "generatablePoaDocumentsForExternal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "templateSlug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalUploadDocumentsWizardGeneratablePoaDocsQuery",
    "selections": (v5/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalUploadDocumentsWizardGeneratablePoaDocsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "74a846dfb37714f19b5a73f95837a526",
    "id": null,
    "metadata": {},
    "name": "ExternalUploadDocumentsWizardGeneratablePoaDocsQuery",
    "operationKind": "query",
    "text": "query ExternalUploadDocumentsWizardGeneratablePoaDocsQuery(\n  $entityType: TemplateEntityTypeEnum!\n  $entitySlug: String!\n  $customerType: String\n  $language: String\n  $customerCountry: String\n) {\n  generatablePoaDocumentsForExternal(entityType: $entityType, entitySlug: $entitySlug, customerType: $customerType, language: $language, customerCountry: $customerCountry) {\n    templateSlug\n  }\n}\n"
  }
};
})();
(node as any).hash = '81ec99f18e3f488f34bace5b356b7aae';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PoaEsignRequestGenerateAndSendEsignQueryVariables = {
    token: string;
    templates: Array<string>;
    customerLanguage: string;
    customerCountry: string;
    customerType: string;
};
export type PoaEsignRequestGenerateAndSendEsignQueryResponse = {
    readonly poaEsign: {
        readonly esignUrl: string | null;
        readonly success: boolean;
        readonly message: string;
    } | null;
};
export type PoaEsignRequestGenerateAndSendEsignQuery = {
    readonly response: PoaEsignRequestGenerateAndSendEsignQueryResponse;
    readonly variables: PoaEsignRequestGenerateAndSendEsignQueryVariables;
};



/*
query PoaEsignRequestGenerateAndSendEsignQuery(
  $token: String!
  $templates: [String!]!
  $customerLanguage: String!
  $customerCountry: String!
  $customerType: String!
) {
  poaEsign(token: $token, templates: $templates, customerLanguage: $customerLanguage, customerCountry: $customerCountry, customerType: $customerType) {
    esignUrl
    success
    message
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerCountry"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerLanguage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "templates"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "customerCountry",
        "variableName": "customerCountry"
      },
      {
        "kind": "Variable",
        "name": "customerLanguage",
        "variableName": "customerLanguage"
      },
      {
        "kind": "Variable",
        "name": "customerType",
        "variableName": "customerType"
      },
      {
        "kind": "Variable",
        "name": "templates",
        "variableName": "templates"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "EsignResponse",
    "kind": "LinkedField",
    "name": "poaEsign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "esignUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PoaEsignRequestGenerateAndSendEsignQuery",
    "selections": (v5/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PoaEsignRequestGenerateAndSendEsignQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "6bd32ec96819ddc198c6404135d40f41",
    "id": null,
    "metadata": {},
    "name": "PoaEsignRequestGenerateAndSendEsignQuery",
    "operationKind": "query",
    "text": "query PoaEsignRequestGenerateAndSendEsignQuery(\n  $token: String!\n  $templates: [String!]!\n  $customerLanguage: String!\n  $customerCountry: String!\n  $customerType: String!\n) {\n  poaEsign(token: $token, templates: $templates, customerLanguage: $customerLanguage, customerCountry: $customerCountry, customerType: $customerType) {\n    esignUrl\n    success\n    message\n  }\n}\n"
  }
};
})();
(node as any).hash = '49ed3380e97258e114e49c42e7177197';
export default node;

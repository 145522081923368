/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type insignGenerateAndRegisterDocumentForSigningInput = {
    clientMutationId?: string | null;
    entitySlug: string;
    entityType: string;
    templateSlug: string;
    folderSlug: string;
    attachmentSlugIds?: Array<string> | null;
    customerType?: string | null;
    language?: string | null;
    email?: string | null;
    customerCountry?: string | null;
    templateList?: Array<string> | null;
};
export type ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutationVariables = {
    input: insignGenerateAndRegisterDocumentForSigningInput;
};
export type ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutationResponse = {
    readonly insignGenerateAndRegisterDocumentForSigning: {
        readonly clientMutationId: string | null;
        readonly esignLink: string;
    } | null;
};
export type ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation = {
    readonly response: ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutationResponse;
    readonly variables: ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutationVariables;
};



/*
mutation ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation(
  $input: insignGenerateAndRegisterDocumentForSigningInput!
) {
  insignGenerateAndRegisterDocumentForSigning(input: $input) {
    clientMutationId
    esignLink
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "insignGenerateAndRegisterDocumentForSigningPayload",
    "kind": "LinkedField",
    "name": "insignGenerateAndRegisterDocumentForSigning",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "esignLink",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2ceab2bdec9d4a16db112218cf95a4f6",
    "id": null,
    "metadata": {},
    "name": "ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation",
    "operationKind": "mutation",
    "text": "mutation ExternalUploadDocumentsWizardGenerateAndSendForSignatureMutation(\n  $input: insignGenerateAndRegisterDocumentForSigningInput!\n) {\n  insignGenerateAndRegisterDocumentForSigning(input: $input) {\n    clientMutationId\n    esignLink\n  }\n}\n"
  }
};
})();
(node as any).hash = '440e47c30e447b95edac90f9d7f11643';
export default node;

import React, { useEffect, useState } from 'react'
import SelectDropdown from './SelectDropdown'
import { CUSTOMER_COUNTRY, CUSTOMER_TYPE_OPTIONS, POA_LANGUAGE_OPTIONS, SHIPMENT_TYPE_OPTIONS } from './constants'
import { Checkbox, FormControl, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'

const useStyles = makeStyles({
  customFormControl: {
    width: '200px', // Set your desired width
  },
})

interface PoaTemplateFormProps {
  vin: string | null
  vinShipmentType: string | null
  setSelectedTemplates: (templates: any) => void
}

const PoaTemplateForm: React.FC<PoaTemplateFormProps> = ({ vinShipmentType, setSelectedTemplates }) => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [formValues, setFormValues] = useState({
    customerType: '',
    customerLanguage: '',
    customerCountry: '',
    poaLanguage: 'de',
    shipmentType: '',
  })

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target
    if (name) {
      setFieldValue(name, value)
    }
    if (name === 'shipmentType') {
      getTemplateIds()
    } else if (name === 'poaLanguage') {
      handlePoaChange(value as string)
    }
    setFormValues({
      ...formValues,
      [name as string]: value,
    })
  }

  const handlePoaChange = (newLanguage: string) => {
    const updatedTemplateList = templateList.map((template: Template) => ({
      ...template,
      name: `${template.name.slice(0, -2)}${newLanguage}`,
    }))

    setSelectedTemplates(
      updatedTemplateList.filter((template: { selected: any }) => template.selected).map((template: { name: any }) => template.name)
    )
    setTemplateList(updatedTemplateList)
  }

  interface Template {
    name: string
    selected: boolean
  }

  const customTemplateList = [
    {
      name: `vehicle-poa-${formValues.poaLanguage}`,
      selected: false,
    },
    {
      name: `general-poa-${formValues.poaLanguage}`,
      selected: false,
    },
    {
      name: `fiscal-poa-${formValues.poaLanguage}`,
      selected: false,
    },
    {
      name: `photo-id-${formValues.poaLanguage}`,
      selected: false,
    },
  ]
  const [templateList, setTemplateList] = useState<any>([])
  const classes = useStyles()

  useEffect(() => {
    if (formValues.customerLanguage && formValues.customerType && formValues.customerCountry) {
      getTemplateIds()
    }
  }, [formValues.customerLanguage, formValues.customerType, formValues.customerCountry])

  const getTemplateIds = () => {
    const newShipmentType = vinShipmentType || formValues.shipmentType
    let templateIds: Iterable<any> | null | undefined = []
    if (newShipmentType === 'other' || newShipmentType === 'package') {
      if (formValues.customerType === 'person') {
        templateIds = [`general-poa-${formValues.customerLanguage}`, `photo-id-${formValues.customerLanguage}`]
      } else if (formValues.customerType === 'company') {
        if (formValues.customerCountry === 'DE') {
          templateIds = ['general-poa-de', 'photo-id-de']
        } else {
          templateIds = [
            `general-poa-${formValues.customerLanguage}`,
            `fiscal-poa-${formValues.customerLanguage}`,
            `photo-id-${formValues.customerLanguage}`,
          ]
        }
      } else {
        templateIds = []
      }
    } else {
      if (formValues.customerType === 'person') {
        templateIds = [`vehicle-poa-${formValues.customerLanguage}`, `photo-id-${formValues.customerLanguage}`]
      } else if (formValues.customerType === 'company') {
        if (formValues.customerCountry === 'DE') {
          templateIds = ['vehicle-poa-de', 'photo-id-de']
        } else {
          templateIds = [
            `vehicle-poa-${formValues.customerLanguage}`,
            `fiscal-poa-${formValues.customerLanguage}`,
            `photo-id-${formValues.customerLanguage}`,
          ]
        }
      } else {
        templateIds = []
      }
    }

    const generatableTemplateNames = new Set(templateIds)
    const templates = [
      ...Array.from(templateIds).map((doc) => ({ name: doc, selected: true })),
      ...customTemplateList
        .filter((doc: { name: string }) => !generatableTemplateNames.has(doc.name.replace(/-\w{2}$/, `-${formValues.customerLanguage}`)))
        .map((doc: { name: string; selected: boolean }) => ({
          ...doc,
          name: `${doc.name.replace(/-\w{2}$/, '')}-${formValues.customerLanguage}`,
          selected: false,
        })),
    ]
    setSelectedTemplates(templates.filter((template) => template.selected).map((template) => template.name))
    setTemplateList(templates)
  }

  const handleTemplateSelect = (name: string) => {
    const updatedTemplateList = templateList.map((template: { name: string; selected: any }) =>
      template.name === name ? { ...template, selected: !template.selected } : template
    )
    setTemplateList(updatedTemplateList)
    setSelectedTemplates(
      updatedTemplateList.filter((template: { selected: any }) => template.selected).map((template: { name: any }) => template.name)
    )
  }

  return (
    <div>
      <SelectDropdown
        label='Customer Type'
        onChange={handleChange}
        value={formValues.customerType}
        name='customerType'
        options={CUSTOMER_TYPE_OPTIONS}
      />
      <SelectDropdown
        label='Customer Language'
        onChange={handleChange}
        value={formValues.customerLanguage}
        name='customerLanguage'
        options={POA_LANGUAGE_OPTIONS}
      />
      <SelectDropdown
        label='Customer Country'
        onChange={handleChange}
        value={formValues.customerCountry}
        name='customerCountry'
        options={CUSTOMER_COUNTRY}
      />
      <FormControl fullWidth margin='normal'>
        <TextField
          size='small'
          variant='standard'
          label='Email'
          value={values.email}
          disabled={true}
          onChange={(e) => setFieldValue('email', e.target.value)}
        />
      </FormControl>
      {vinShipmentType ? (
        <div>
          <FormControl fullWidth margin='normal'>
            <TextField
              size='small'
              variant='standard'
              label='Shipment Type'
              value={vinShipmentType}
              disabled={true}
              onChange={(e) => setFieldValue('email', e.target.value)}
            />
          </FormControl>
        </div>
      ) : (
        <SelectDropdown
          label='Shipment Type'
          name='shipmentType'
          value={formValues.shipmentType}
          options={SHIPMENT_TYPE_OPTIONS}
          onChange={handleChange}
        />
      )}

      {templateList.length > 0 && (
        <>
          <Typography variant={'subtitle1'}>The following documents will be generated</Typography>
          <FormControl fullWidth margin='normal' className={classes.customFormControl}>
            <SelectDropdown
              label='POA Language'
              onChange={handleChange}
              value={formValues.poaLanguage}
              name='poaLanguage'
              options={POA_LANGUAGE_OPTIONS}
            />
          </FormControl>
          <List component='div' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%' }}>
            {templateList.map((template: { name: string; selected: boolean }, index: number) => (
              <ListItem key={index} alignItems='flex-start'>
                <ListItemIcon>
                  <Checkbox
                    size='small'
                    checked={template.selected}
                    onChange={() => handleTemplateSelect(template.name)}
                    style={{ padding: '0 4px' }}
                  />
                </ListItemIcon>
                <ListItemText primary={template.name} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </div>
  )
}

export default PoaTemplateForm

import React from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useField } from 'formik'

interface DropdownProps {
  label: string
  name: string
  options: { key: string; value: string; label: string }[]
  value?: string
  onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
}

const SelectDropdown: React.FC<DropdownProps> = ({ label, name, options, value, onChange }) => {
  const [field, meta] = useField(name)

  return (
    <FormControl fullWidth margin='normal' error={!!meta.error && meta.touched}>
      <InputLabel>{label}</InputLabel>
      <Select {...field} value={field.value || value || ''} onChange={onChange}>
        {options.map(({ key, value, label }) => (
          <MenuItem key={key} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}

export default SelectDropdown

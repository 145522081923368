import React from 'react'
import styled from 'styled-components'

const InfoBox = styled.div`
  background-color: #f0f8ff;
  border: 1px solid #cce7ff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.5;
  color: #333; /* Neutral text color */
`

const Highlight = styled.span`
  font-weight: bold;
  color: #0056b3; /* Emphasis text color */
`

const PoaInformationalText: React.FC = () => {
  return (
    <InfoBox>
      <p>
        Please enter your <Highlight>Email</Highlight> to begin the eSign process.If you have a shipment <Highlight>VIN number</Highlight>,
        kindly provide it as well.
      </p>
      <p>Once submitted, please check your email for a verification link to proceed with the eSign process.</p>
    </InfoBox>
  )
}

export default PoaInformationalText

import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { AttachFile, Clear, Send } from '@material-ui/icons'
import { Autocomplete, Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense, unstable_useTransition, useEffect, useMemo, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import useNiceMutation from '../../mutations/useNiceMutation'
import { SendMessageDialogFormQuery } from '../../__generated__/SendMessageDialogFormQuery.graphql'
import { infoSendMessageInput, SendMessageDialogMutation } from '../../__generated__/SendMessageDialogMutation.graphql'
import Button from '../Button'
import LoadingDots from '../LoadingDots'
import OdysseyDialog from '../OdysseyDialog'
import { MessageEntityEnum } from './types'

interface Props {
  entitySlug: string
  entityType: MessageEntityEnum
  open: boolean
  onClose?: () => void
  initialTemplateSlug?: string | null
}

const HorizontalFlex = styled.div`
  display: flex;
  align-items: center;
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
`

const TitleOptionsFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  min-height: 32px;
  margin-bottom: 8px;
  background-color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[200] : props.theme.palette.grey[800])};
  padding: 0 24px;
`

const TitleOptionsSelect = styled(Select)`
  & > .MuiSelect-root {
    padding: 0 16px;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[200] : props.theme.palette.grey[800])};
  }

  & > .MuiSelect-icon {
    right: 8px;
  }
`

const TemplateSelect = styled(TitleOptionsSelect)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`

const StyledInputBase = styled(InputBase)`
  height: 100%;
  width: 100%;
`

const StyledAutoCompleteInputBase = styled(StyledInputBase)`
  height: 100%;
  width: 100%;
  padding: 0 16px;

  & > .MuiAutocomplete-endAdornment {
    right: 7px;
  }
`

const FieldContainer = styled.div`
  padding: 0 24px;
`

const FieldInputContainer = styled.div`
  display: flex;
  align-items: center;
`

const FieldLabel = styled(Typography).attrs({ variant: 'body2', color: 'textSecondary' })`
  margin-right: 16px;
`

const StyledDivider = styled(Divider)`
  padding-left: 24px;
  margin: 8px 0;
`

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
`

const SendMessageDialog: React.FC<Props> = ({ entitySlug, entityType, open, onClose, initialTemplateSlug }) => {
  return (
    <OdysseyDialog open={open} onClose={onClose} fullWidth maxWidth='sm' style={{ maxWidth: '960px', margin: 'auto' }}>
      <DialogTitle>New Message</DialogTitle>
      <Suspense fallback={<SendMessageDialogInternal.Skeleton />}>
        <SendMessageDialogInternal
          entityType={entityType}
          entitySlug={entitySlug}
          onClose={onClose}
          initialTemplateSlug={initialTemplateSlug}
        />
      </Suspense>
    </OdysseyDialog>
  )
}

interface InternalProps {
  entitySlug: string
  entityType: MessageEntityEnum
  onClose?: () => void
  initialTemplateSlug?: string | null
  bodyContentFilter?: string[]
}

export const SendMessageDialogInternal: React.FC<InternalProps> & { Skeleton: React.FC } = ({
  entitySlug,
  entityType,
  onClose,
  initialTemplateSlug,
  bodyContentFilter,
}) => {
  const [templateSlug, setTemplateSlug] = useState<null | string>(initialTemplateSlug === undefined ? null : initialTemplateSlug)
  const [language, setLanguage] = useState<null | string>('en')
  const { messageForm } = useLazyLoadQuery<SendMessageDialogFormQuery>(query, {
    entitySlug: entitySlug,
    entityType: entityType,
    templateSlug: templateSlug,
    bodyContentFilter: bodyContentFilter,
  })
  const selectedMessage = useMemo(() => messageForm?.messages.find((message) => message.language === language), [messageForm, language])
  const [startTemplateTransition, templateTransitionIsInProgress] = unstable_useTransition()

  const [newMessageInput, setNewMessageInput] = useState<infoSendMessageInput>({
    body: selectedMessage?.body || '',
    entitySlug: entitySlug,
    entityType: entityType,
    subject: selectedMessage?.subject || '',
    templateSlug: `${templateSlug}_${language}` || '',
    to: (selectedMessage?.to as string[]) || [],
    // attachments: (selectedMessage?.attachments as string[]) || [],
    subjectParams: selectedMessage?.subjectParams as string[],
    bodyParams: selectedMessage?.bodyParams as string[],
    signature: selectedMessage?.signature || '',
  })
  const [bodyVariable, setBodyVariable] = useState<null | string>('')
  useEffect(() => {
    setNewMessageInput({
      body: selectedMessage?.body || '',
      entitySlug: entitySlug,
      entityType: entityType,
      subject: selectedMessage?.subject || '',
      templateSlug: `${templateSlug || messageForm?.templateSlug || ''}_${language}` || '',
      to: (selectedMessage?.to as string[]) || [],
      // attachments: (selectedMessage?.attachments as string[]) || [],
      subjectParams: selectedMessage?.subjectParams as string[],
      bodyParams: selectedMessage?.bodyParams as string[],
      userParams: null,
      signature: selectedMessage?.signature || '',
    })
  }, [selectedMessage, entitySlug, entityType, templateSlug])

  const [commit, sendMessageIsInFlight] = useNiceMutation<SendMessageDialogMutation>(mutation)

  const onSubmit = () => {
    const valueToAdd = bodyVariable || '-'
    const updatedState = {
      ...newMessageInput,
      userParams: valueToAdd,
    }
    setNewMessageInput(updatedState)
    commit({
      variables: {
        input: updatedState,
      },
      onCompleted: (_res, errors) => {
        if (!errors) {
          onClose && onClose()
        }
      },
    })
  }

  return (
    <>
      <StyledDialogContent dividers>
        <TitleOptionsFieldsContainer>
          <TemplateSelect
            value={templateSlug || messageForm?.templateSlug || ''}
            onChange={(e) => {
              startTemplateTransition(() => {
                setTemplateSlug(e.target.value as string)
              })
            }}
            input={
              <StyledInputBase
                fullWidth
                endAdornment={
                  templateTransitionIsInProgress ? (
                    <InputAdornment position='end' style={{ display: 'flex', marginRight: '42px' }}>
                      <LoadingDots variant='dark' />
                    </InputAdornment>
                  ) : null
                }
              />
            }
          >
            {messageForm?.possibleTemplates.map((template) => (
              <MenuItem key={template.slug} value={template.slug}>
                {template.title}
              </MenuItem>
            ))}
          </TemplateSelect>
          <TitleOptionsSelect value={language} onChange={(e) => setLanguage(e.target.value as string)} input={<StyledInputBase fullWidth />}>
            {messageForm?.messages.map((message) => (
              <MenuItem key={message.language} value={message.language}>
                {message.languageTitle}
              </MenuItem>
            ))}
          </TitleOptionsSelect>
        </TitleOptionsFieldsContainer>
        <FieldContainer>
          <Autocomplete
            multiple
            freeSolo
            disabled={sendMessageIsInFlight || templateTransitionIsInProgress}
            groupBy={(option) => (typeof option === 'string' ? '' : option.group)}
            options={[...(messageForm?.possibleReceivers || [])]}
            renderOption={(option) =>
              typeof option === 'string' ? (
                <div>
                  <Typography variant='body2'>{option}</Typography>
                </div>
              ) : (
                <div>
                  <Typography variant='body2'>
                    {option.name} - {option.businessPhone} {option.isPersonal ? '(PN)' : ''}
                  </Typography>
                  <Typography variant='caption' color='textSecondary'>
                    {option.roles.join(', ')}
                  </Typography>
                </div>
              )
            }
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : `${option.name} - ${option.businessPhone} ${option.isPersonal ? '(PN)' : ''}`
            }
            value={newMessageInput.to.map(
              (businessPhone) => messageForm?.possibleReceivers.find((receiver) => receiver.businessPhone === businessPhone) || businessPhone
            )}
            renderInput={(params) => (
              <FieldInputContainer>
                <FieldLabel>To:</FieldLabel>
                <StyledAutoCompleteInputBase inputProps={params.inputProps} {...params.InputProps} />
              </FieldInputContainer>
            )}
            renderTags={(options, getTagProps) =>
              options.map((option, index) => (
                <Chip
                  key={typeof option === 'string' ? option : option.businessPhone}
                  variant='outlined'
                  label={typeof option === 'string' ? option : `${option.name} - ${option.businessPhone} ${option.isPersonal ? '(PN)' : ''}`}
                  {...getTagProps({ index })}
                  deleteIcon={<Clear />}
                />
              ))
            }
            onChange={(event, newValue) => {
              setNewMessageInput((prev) => ({ ...prev, to: newValue.map((val) => (typeof val === 'string' ? val : val.businessPhone)) }))
            }}
          />
        </FieldContainer>
        <StyledDivider />
        <FieldContainer>
          <FieldInputContainer>
            <FieldLabel>Subject:</FieldLabel>
            <StyledInputBase
              value={newMessageInput.subject}
              // disabled={sendMessageIsInFlight || templateTransitionIsInProgress}
              disabled={true}
              onChange={(evt) => setNewMessageInput((prevState) => ({ ...prevState, subject: evt.target.value }))}
            />
          </FieldInputContainer>
        </FieldContainer>
        <StyledDivider />
        <FieldContainer>
          <FieldLabel>Body:</FieldLabel>
          <StyledInputBase
            multiline
            rows={10}
            rowsMin={10}
            // placeholder='Type body here...'
            value={newMessageInput.body}
            disabled={true}
            // onChange={(evt) => setNewMessageInput((prevState) => ({ ...prevState, body: evt.target.value }))}
          />
        </FieldContainer>
        <FieldContainer>
          <StyledInputBase
            multiline
            rows={10}
            rowsMin={10}
            placeholder='Type more here...'
            value={bodyVariable}
            disabled={sendMessageIsInFlight || templateTransitionIsInProgress}
            onChange={(evt) => setBodyVariable(evt.target.value)}
          />
        </FieldContainer>
        {newMessageInput.signature && (
          <FieldContainer>
            <StyledInputBase
              multiline
              rows={10}
              rowsMin={10}
              value={newMessageInput.signature}
              disabled={true}
              onChange={(evt) => setNewMessageInput((prevState) => ({ ...prevState, signature: evt.target.value }))}
            />
          </FieldContainer>
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <HorizontalFlex>
          {/* <IconButton onClick={() => setAttachmentSelectionOpen(true)} disabled={sendMessageIsInFlight}>
            <AttachFile />
          </IconButton> */}
          <Button
            variant='contained'
            onClick={onSubmit}
            startIcon={<Send />}
            disabled={
              sendMessageIsInFlight ||
              !(messageForm?.possibleReceivers && messageForm.possibleReceivers.length > 0) ||
              newMessageInput.to.length === 0
            }
          >
            {sendMessageIsInFlight ? <LoadingDots /> : 'Send'}
          </Button>
        </HorizontalFlex>
      </StyledDialogActions>
    </>
  )
}

SendMessageDialogInternal.Skeleton = () => (
  <>
    <StyledDialogContent dividers>
      <TitleOptionsFieldsContainer>
        <div style={{ height: '31px' }}></div>
      </TitleOptionsFieldsContainer>
      <FieldContainer>
        <FieldInputContainer>
          <FieldLabel>To:</FieldLabel>
          <Skeleton>
            <Typography variant='body1'>................................</Typography>
          </Skeleton>
        </FieldInputContainer>
      </FieldContainer>
      <StyledDivider />
      <FieldContainer>
        <FieldInputContainer>
          <FieldLabel>Subject:</FieldLabel>
          <Skeleton>
            <Typography variant='body1'>................................</Typography>
          </Skeleton>
        </FieldInputContainer>
      </FieldContainer>
      <StyledDivider />
      <FieldContainer style={{ marginBottom: '16px' }}>
        <Skeleton variant='rect' height='190px'></Skeleton>
      </FieldContainer>
    </StyledDialogContent>
    <StyledDialogActions>
      <Button disabled>Cancel</Button>
      <HorizontalFlex>
        <IconButton disabled>
          <AttachFile />
        </IconButton>
        <Button variant='contained' startIcon={<Send />} disabled>
          Send
        </Button>
      </HorizontalFlex>
    </StyledDialogActions>
  </>
)

const query = graphql`
  query SendMessageDialogFormQuery(
    $entityType: MessageEntityTypeEnum!
    $entitySlug: String!
    $templateSlug: String
    $bodyContentFilter: [String!]
  ) {
    messageForm(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug, bodyContentFilter: $bodyContentFilter) {
      entityType
      entitySlug
      templateSlug
      defaultLanguage
      messages {
        language
        languageTitle
        subject
        to
        body
        attachments
        subjectParams
        bodyParams
        signature
      }
      possibleTemplates {
        title
        slug
      }

      possibleReceivers {
        businessPhone
        isPersonal
        roles
        name
        group
      }

      attachmentFolderSets {
        folders {
          slug
          name
          attachments {
            attachmentSlug
            ...EntityAttachmentListPreviewer_attachment
            ...EntityAttachment_attachment
          }
        }
      }
      ...AttachmentSelectionDialog_messageForm
    }
  }
`

const mutation = graphql`
  mutation SendMessageDialogMutation($input: infoSendMessageInput!) {
    infoSendMessage(input: $input) {
      clientMutationId
    }
  }
`

export default SendMessageDialog

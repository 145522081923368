import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense, useContext } from 'react'
import ExternalFoldersCard from '../../components/File/ExternalFoldersCard'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { ExternalShipmentShowHomeContentQuery } from '../../__generated__/ExternalShipmentShowHomeContentQuery.graphql'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import ExternalTimeline from '../../components/ExternalTimeline/ExternalTimeline'
import ExternalShipmentInfoCard from './ExternalShipmentInfoCard'
import SessionContext from '../../SessionContext'
import LiveRequest from '../../components/LiveRequest'
import { AddNotes } from '../../components/Notes'
import LinkedContactsCard from '../../components/LinkedContactsCard'
import EmailsCard from '../../components/Email/EmailsCard'

interface Props {
  slug: string
}

const ExternalShipmentShowHomeContent: React.FC<Props> & { Skeleton: React.FC } = ({ slug }) => {
  const { externalShipmentShow: shipment } = useLazyLoadQueryWithSubscription<ExternalShipmentShowHomeContentQuery>(
    shipmentHomeQuery,
    { slug: slug },
    { fetchPolicy: 'store-and-network', subscriptionOptions: { entityType: 'shipments', entitySlug: slug } }
  )
  const { user } = useContext(SessionContext)
  const consignee = user?.contact?.displayRoles.filter((role) => role.value == 'consignee').length == 1

  return (
    <ResponsiveGrid
      type='show-layout'
      left={[
        <>
          {consignee && <ExternalShipmentInfoCard key='info-card' shipment={shipment} />}
          <ExternalFoldersCard
            key='photos'
            folderType='photos'
            folders={shipment.folders}
            title='Photos'
            entityType='shipments'
            entitySlug={shipment.slug}
          />
        </>,
      ]}
      middle={[
        <LinkedContactsCard
          key='contacts'
          linkedContacts={shipment.linkedContacts}
          draftContacts={shipment.draftContacts}
          draftLinkedContactRoles={shipment.draftLinkedContactRoles}
          entitySlug={shipment.slug}
          entityType='shipment'
        />,
        <EmailsCard key='emails' entityType='shipments' entitySlug={shipment.slug} emails={shipment.emails} />,
        <ExternalFoldersCard
          key='documents'
          folderType='documents'
          folders={shipment.folders}
          title='Documents'
          entityType='shipments'
          entitySlug={shipment.slug}
        />,
      ]}
      right={[
        <>
          <Suspense key='notes' fallback={<AddNotes.Skeleton />}>
            <AddNotes entitySlug={slug} entityType='shipments' />
          </Suspense>
          <Suspense key='timeline' fallback={<ExternalTimeline.Skeleton />}>
            <ExternalTimeline slug={shipment.slug} type='shipments' />
          </Suspense>
          <LiveRequest key='live-requests' liveRequests={shipment.liveRequests} entitySlug={slug} entityType='shipments' />
        </>,
      ]}
    />
  )
}

export const shipmentHomeQuery = graphql`
  query ExternalShipmentShowHomeContentQuery($slug: String!) {
    externalShipmentShow(slug: $slug) {
      slug
      ...ExternalShipmentInfoCard_shipment
      ...ExternalShipmentCompanyCard_shipment
      ...ExternalUploadDocumentsWizard_shipment
      folders {
        ...ExternalFoldersCard_folders
        ...ExternalUploadDocumentsWizard_folders
      }
      draftContacts {
        ...LinkedContactsCard_draftContacts
      }
      linkedContacts {
        ...LinkedContactsCard_linkedContacts
      }
      draftLinkedContactRoles {
        ...LinkedContactsCard_draftLinkedContactRoles
      }
      liveRequests {
        ...LiveRequest_liveRequests
      }
      emails {
        ...EmailsCard_emails
      }
    }
  }
`

ExternalShipmentShowHomeContent.Skeleton = () => <>Loading...</>

export default ExternalShipmentShowHomeContent
